import React, { Component } from 'react'
import { connect } from 'react-redux'
import { languageSwitchItem } from '../actions/locales'
import { createSession, sendInputData, sendDSData, sendSessionOption, verifyDSData,
			getVerifiedData, deleteSession, getSignedData, modifyDS, getModifiedDSBase64Data, getModifyDSResult } from '../actions/api'
import { setVerifyResultData, setDSEncodingType, setVerifyDSEncodingType, setInputTextForVerifyDS,
		 setVerifyResultText, setVerifyInputText, setInputTextDSForVerify, setTextSignedData,
		 clearInputTextDSForVerify, clearVerifyInputText, clearVerifyResultText, clearTextSignedData, setVerifyDSTextExpand,
		 setInputTextForUpgradeUint8Arr, setCadesType, setUpgradedDSInBase64, setSignatureDifferenceType, clearUpgradedDSInBase64 } from '../actions/localStates'
import { StringToUTF16LEArray, StringToArray, toByteArray, ArrayToString, UTF16LEArrayToString } from '../actions/stringEncodersFuncs'
import { bindActionCreators } from 'redux';
import i18next from 'i18next'

class VerifyTextDSContainer extends Component {

	constructor(props) {
    super(props);

	    this.state = {
			inputText: "",
			inputTextDS: "",
			baseUrl: "https://local.cipher.kiev.ua:9090/api/v1/ticket/",
			uuid: null,
			collapseVerifyDSTextShow: "",
			isModify: false,
			responseMessage: "",
			responseFailureCause: ""
		}

		this.showDsVerifyResultsText = this.showDsVerifyResultsText.bind(this)
		this.textEncodingHendler = this.textEncodingHendler.bind(this)
		this.textInputHendler = this.textInputHendler.bind(this)
		this.textDSInputHendler = this.textDSInputHendler.bind(this)
	}

	componentDidMount() {
		this.props.expandedCardsReducer.verifyTextExpanded ? this.setState({collapseVerifyDSTextShow: "card-body collapse show"}) : this.setState({collapseVerifyDSTextShow: "card-body collapse"})
	}

	expandVerifyDSText() {
		this.props.actions.setVerifyDSTextExpand(this.props.expandedCardsReducer.verifyTextExpanded)
		this.props.expandedCardsReducer.verifyTextExpanded ? this.setState({collapseVerifyDSTextShow: "card-body collapse"}) : this.setState({collapseVerifyDSTextShow: "card-body collapse show"})
	}

	formatDate(dateInString) {
        var localeDateOptions = {
            "day": "numeric",
            "month": "numeric",
            "year": "numeric",
            "hour": "2-digit",
            "minute": "2-digit",
            "second": "2-digit",
            "timeZoneName": "short"
        }

        return new Date(dateInString).toLocaleDateString(i18next.language, localeDateOptions);
    }

    cleanUpForm() {
    	this.props.actions.clearInputTextDSForVerify()
    	this.props.actions.clearVerifyInputText()
    	this.props.actions.clearVerifyResultText()
    	this.props.actions.clearTextSignedData()
    	this.props.actions.clearUpgradedDSInBase64()
    }

    startVerify() {
		if (this.props.defaultState.showUpgradeCard) {
			this.setState({isModify: true})
			this.modifyDS();
		} else {
			this.setState({isModify: false})
			this.verifyDS();
		}
	}

    constructTimestampDetails(aTs, aTsType) {
        let contentTs = i18next.t("dataTs");
        let signatureTs = i18next.t("dsTs");
        let tsaDS = i18next.t("tsaCertificateSerialNumberDS");
        let tsa = i18next.t("tsaCertificateSerialNumber");
        let result = [];

        let tsTypeString = function () {
            if (aTsType === "content") {
                return contentTs;
            }
            if (aTsType === "signature") {
                return signatureTs;
            }
            return "";
        }

        let tsaTsTypeString = function () {
            if (aTsType === "content") {
                return tsa;
            }
            if (aTsType === "signature") {
                return tsaDS;
            }
            return "";
        }
        
        if (aTs && aTs.dateTime) {
            if (aTs.isFailure) {
                result.push(<div>{tsTypeString() + i18next.t("ds:errorWhileVerifying")}</div>);
            } else {
            	result.push(
	            	<div>
	            		<div>
	            			<span className="font-weight-bold">{tsTypeString()}</span>
	            			{
			            		": "
			                    + (aTs.isValid ? i18next.t("ds:sheIsValid") : i18next.t("ds:sheIsInvalid"))
			                    + "; "
			                    + this.formatDate(aTs.dateTime)
		                	}
		                	{
		                		aTs.tsaCertificateSerialNumber
		                		? <div><span className="font-weight-bold">{tsaTsTypeString()}</span>
				                	{
					                    aTs.tsaCertificateSerialNumber
				                	}
				                </div>
				                : null
		                	}	                	
	            		</div>
	                </div>
                );
            }
        }
        return result;
    }

	showDsVerifyResultsText(verifyResults, elementToDisplay) {
		let verifyResultDiv = [];
		

        for (var i = 0; i < verifyResults.length; i += 1) {
        	let signerNumber = [];
			let signerStatus = [];
			let signerDetails = [];
			let tsDetails = [];
			let tempString = [];

			if (verifyResults[i].signatureTimestamps && verifyResults[i].signatureTimestamps.length) {
				for (var j = 0; j < verifyResults[i].signatureTimestamps.length; j += 1) {
					tsDetails.push(this.constructTimestampDetails(verifyResults[i].signatureTimestamps[j], "signature"))
				}
			} else {
				if (!this.props.defaultState.showUpgradeCard && (Object.keys(verifyResults[i].contentTimestamp).length === 0)) {

					tempString.push(<div style={{fontSize: "14px"}}>
						<hr style={{marginBottom: "0px", marginTop: "5px"}} />
						<span><p>{i18next.t("ds:lawMsgFirstRow")}</p></span>
						<span><p>{i18next.t("ds:lawMsgSecondRow")}</p></span>
						<span><p>{i18next.t("ds:lawMsgThirdRow")}</p></span>
					</div>);
				}
			}

			

        	signerNumber.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
    			{i18next.t("ds:signature") + (i + 1)}
        	</div>)

        	if (verifyResults[i].status === "SUCCESS") {
		        signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
	        			{
	        				verifyResults[i].isValid ? i18next.t("ds:heIsValid") : i18next.t("ds:heIsInvalid")
	        			}
	        			<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
		        			{
		        				verifyResults[i].signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" && verifyResults[i].signerInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("ds:qualifiedTitle") : i18next.t("ds:unqualifiedTitle")
		        			}
		        		</div>
	        		</div>)
		        signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details">
	    			<div className="margin-bottom-5">
	    				<span className="font-weight-bold">{ i18next.t("ds:signer")}</span> <span>{verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerFullName.value }</span>
	    			</div>
	    				{
							verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerOrganization.value ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:organizationName") }</span> <span>{verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerOrganization.value }</span>
				        		</div> : null
				        } {
							verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerPosition.value ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:title") }</span> <span>{verifyResults[i].signerInfo.ownerCertificateInfo.value.ownerPosition.value }</span>
				        		</div> : null
				        } {
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:drfou") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.drfou.value }</span>
				        		</div> : null
				        } {
				        	verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value ? 
					        	<div>
				    				<span className="font-weight-bold">{ i18next.t("ds:edrpou")}</span><span>{ verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.edrpou.value }</span>
				        		</div> : null
				        } {
				        	verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value ? 
				        		<div>
				    				<span className="font-weight-bold">{ i18next.t("ds:eddrun")}</span><span>{ verifyResults[i].signerInfo.extensionsCertificateInfo.value.personalData.value.eddrun.value }</span>
				        		</div> : null
				        }
	    			<div className="margin-bottom-5">
	    				<span className="font-weight-bold">{ i18next.t("ds:ca")}</span><span>{verifyResults[i].signerInfo.issuerCertificateInfo.value.issuerFullName.value }</span>
	        		</div>
	        			{
				        	verifyResults[i].signerInfo.certificateSerialNumber.value ? 
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("certificateSerialNumberTitle")}</span><span>{verifyResults[i].signerInfo.certificateSerialNumber.value.replace(/\s/g, "") }</span>
				        		</div> : null
	                    }
	    			<div className="margin-bottom-5">
	    				{
	    					verifyResults[i].signingDateTime !== undefined && verifyResults[i].signingDateTime.length > 0
	    					? <span><span className="font-weight-bold">{ i18next.t("ds:dsDate")}</span><span>{this.formatDate(verifyResults[i].signingDateTime)}</span></span>
	    					: null
	    				}
	    			</div>
	    			<div>
	    				{
	    					this.constructTimestampDetails(verifyResults[i].contentTimestamp, "content")
	    				}
	    			</div>
	    			<div>
	    				{
	    					tsDetails
		                }
	    			</div>
	    			<div>
	    				{
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.isElectronicStamp ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:isElectronicStampTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.isElectronicStamp.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
				        		</div> : null
				        }
	    				{
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.isQualified ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:qualifiedCertTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.isQualified.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
				        		</div> : null
				        }
				        {
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:QSCDTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.isPrivateKeyOnQSCD.value === "true" ? i18next.t("yesUCase") : i18next.t("noUCase")}</span>
				        		</div> : null
				        }
				        {
							verifyResults[i].signerInfo.extensionsCertificateInfo.value.qscdTypeName ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:QSCDTypeTitle") }</span> <span>{verifyResults[i].signerInfo.extensionsCertificateInfo.value.qscdTypeName.value === null ? i18next.t("ds:notDefined") : verifyResults[i].signerInfo.extensionsCertificateInfo.value.qscdTypeName.value}</span>
				        		</div> : null
				        }
				        {
							verifyResults[i].signatureType ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:signatureType") }</span> <span>{verifyResults[i].signatureType === null ? i18next.t("ds:notDefined") : i18next.t("ds:" + verifyResults[i].signatureType)}</span>
				        		</div> : null
				        }
				        {
							verifyResults[i].signatureFormat ?
		                        <div>
				    				<span className="font-weight-bold">{ i18next.t("ds:signatureFormat") }</span> <span>{verifyResults[i].signatureFormat === null ? i18next.t("ds:notDefined") : i18next.t("ds:" + verifyResults[i].signatureFormat)}</span>
				        		</div> : null
				        }
	    			</div>
	    		</div>)
	        	verifyResultDiv.push(<div className="ptblg-default"  style={{marginLeft: "-10px", marginRight: "-10px", marginTop: "-10px"}}>
	        		{	
	        			this.props.defaultState.showUpgradeCard && !this.props.verifyDefaultState.differenceSignatureType
			    		? <div className="alert alert-warning">
			        		{i18next.t("ds:alreadyModifiedTitle")}
			        	</div>
			        	: null
	        		}
	        		<div className={(verifyResults[i].isValid && tempString.length === 0) ? "alert alert-success" : "alert alert-warning"}>
		        		<div className="row no-left-right-padding">
		        			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 no-left-right-padding" style={{paddingLeft: "11px"}}>{signerNumber} {signerStatus}</div> <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 no-left-right-padding">{signerDetails}</div>
		        		</div>
		        		<div>
			        		{
			        			tempString
			        		}
			        	</div>
		        	</div>
	        	</div>)
	        } else {
		        signerStatus.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 no-left-right-padding">
	        			{i18next.t("ds:notDefined")}
	        		</div>)
		        signerDetails.push(<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 signer-details no-left-right-padding">
		    			<div>
		    				{ verifyResults[i].failureCause }
		    				{
					            verifyResults[i].isUntrustedValid
					            ? <div><p></p><div><b>{i18next.t("ds:signatureValidWithUntrustedTime")}</b></div></div>
					            : null
					        }
		    			</div>
	    			</div>)
	        	verifyResultDiv.push(<div className="ptblg-default" style={{marginLeft: "-10px", marginRight: "-10px", marginTop: "-10px"}}>
	        		<div className={verifyResults[i].isValid ? "alert alert-success" : "alert alert-warning"}>
		        		<div className="row">
		        			<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">{signerNumber} {signerStatus}</div> <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">{signerDetails}</div>
		        		</div>
		        	</div>
	        	</div>)
            }
        }
        return verifyResultDiv
    }

    copyModifiedData() {
		var textArea,
        copy;

	    function isOS() {
	        return navigator.userAgent.match(/ipad|iphone/i);
	    }

	    function createTextArea(text) {
	        textArea = document.createElement('textArea');
	        textArea.value = text;
	        textArea.style.cssText = "position: absolute";
	        textArea.style.cssText = "top: -9999px";
	        document.body.appendChild(textArea);
	    }

	    function selectText() {
	        var range,
	            selection;

	        if (isOS()) {
	            range = document.createRange();
	            range.selectNodeContents(textArea);
	            selection = window.getSelection();
	            selection.removeAllRanges();
	            selection.addRange(range);
	            textArea.setSelectionRange(0, 999999);
	        } else {
	            textArea.select();
	        }
	    }

	    function copyToClipboard() {        
	        document.execCommand('copy');
	        textArea.blur();
	        document.body.removeChild(textArea);
	    }

	    copy = function(text) {
	        createTextArea(text);
	        selectText();
	        copyToClipboard();
		};

		copy(this.props.upgradeTextDSReducer.upgradedDSInBase64);
	}

	textEncodingHendler(e) {
		this.props.actions.setVerifyDSEncodingType(e.target.value)
		this.props.actions.clearTextSignedData()
		this.props.actions.clearVerifyResultText()
		this.props.actions.clearUpgradedDSInBase64()
	}

	textDSInputHendler(e) {
		this.props.actions.setInputTextDSForVerify(e.target.value)
		this.props.actions.clearVerifyResultText()
    	this.props.actions.clearTextSignedData()
		this.props.actions.clearUpgradedDSInBase64()
	}

	textInputHendler(e) {
		this.props.actions.setVerifyInputText(e.target.value)
		this.props.actions.clearVerifyResultText()
    	this.props.actions.clearTextSignedData()
		this.props.actions.clearUpgradedDSInBase64()
	}

	verifyDS() {
		var arr, dsArr, needToGetSignedData = true
		var _this = this
		this.props.verifyDefaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.verifyDefaultState.textInput, false)
			: arr = this.props.actions.StringToArray(this.props.verifyDefaultState.textInput, true)

		if (this.props.verifyDefaultState.textDS.length > 0) {
			try {
	            dsArr = this.props.actions.toByteArray(this.props.verifyDefaultState.textDS);
        		var u8DSArr = new Uint8Array(dsArr);
	        } catch (e) {
	            alert(i18next.t("ds:illegalBase64SignatureFormat"));
	            return;
	        }
		}

		dsArr = this.props.actions.toByteArray(this.props.verifyDefaultState.textDS)

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForVerifyDS(u8Arr)
		this.setState({isLoading: true})
		this.props.actions.createSession(this.props.connectionStatus.baseUrl)
        	.then((response) => {
				this.setState({uuid: response.ticketUuid})
				console.log(response);
				return this.props.defaultState.signatureType === 'detached' ? this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.verifyDefaultState.encodedText)
				.then(() => {
					return this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, u8DSArr)
						.then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
			            .then(() => this.props.actions.verifyDSData(this.props.connectionStatus.baseUrl, this.state.uuid))
			            .then(() => this.props.actions.getVerifiedData(this.props.connectionStatus.baseUrl, this.state.uuid))
			            .then((response) => {
			            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
			            		if (this.state.isModify && this.state.responseFailureCause.length > 0) {
			            			alert(this.state.responseFailureCause);
			            		} else {
			            			alert(response.failureCause);
			            		}
			            		this.props.actions.setVerifyResultText(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
			            	} else {
			            		if (this.state.isModify && this.state.responseMessage.length > 0) {
			            			if (this.props.verifyDefaultState.differenceSignatureType) {
			            				alert(this.state.responseMessage);
			            			} else {
			            				// alert(i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle"));
			            			}
			            		} else {
			            			// alert(i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle"));
			            		}
			            		this.props.actions.setVerifyResultText(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
		                		console.log(response)
			            	}
			            	this.setState({isLoading: false})
		                })
			            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
				}) : this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, u8DSArr)

	            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
	            .then(() => this.props.actions.verifyDSData(this.props.connectionStatus.baseUrl, this.state.uuid))
	            .then(() => this.props.actions.getVerifiedData(this.props.connectionStatus.baseUrl, this.state.uuid))
	            .then((response) => {
	            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
	            		if (this.state.isModify && this.state.responseFailureCause.length > 0) {
	            			alert(this.state.responseFailureCause);
	            		} else {
	            			alert(response.failureCause);
	            		}
	            		this.props.actions.setVerifyResultText(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
	            	} else {
	            		if (this.state.isModify && this.state.responseMessage.length > 0) {
	            			if (this.props.verifyDefaultState.differenceSignatureType) {
	            				alert(this.state.responseMessage);
	            			} else {
	            				// alert(i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle"));
	            			}
	            		} else {
	            			// alert(i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle"));
	            		}
	            		this.props.actions.setVerifyResultText(response.verifyResults, "dsvVerifyResultsTextDs", "verifyResults")
                		console.log(response)
                		response.verifyResults.map((res) => {
	            			if ((res.signatureFormat === "XADES_B_B" || res.signatureFormat === "XADES_B_LT" || res.signatureFormat === "XADES_B_LTA") || (res.signatureType === "ASIC_S" || res.signatureFormat === "ASIC_E")) {
	            				needToGetSignedData = false;
	            			}
	            			console.log(res)
	            		})
	            	}
            	})
            	.then(() => this.props.actions.getSignedData(this.props.connectionStatus.baseUrl, this.state.uuid, needToGetSignedData))
            	.then((response) => {
	            	if (response !== null) {
	            		var reader = new FileReader();
		                reader.onload = function () {
		                    var arrayBuffer = reader.result;
		                    var uintArray = new Uint8Array(arrayBuffer);
		                    var arr = [].slice.call(uintArray);
		                    var s = _this.props.verifyDefaultState.textEncoding === 'UTF-16LE'
		                    	? _this.props.actions.UTF16LEArrayToString(arr)
		                    	: _this.props.actions.ArrayToString(arr)
		                    _this.props.actions.setTextSignedData(s)
		                }
		                reader.readAsArrayBuffer(response);
	            	}
	            	this.setState({isLoading: false})
            	})
	            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
        })
    }

    modifyDS() {
		var arr;

    	this.props.verifyDefaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.verifyDefaultState.textInput, false)
			: arr = this.props.actions.StringToArray(this.props.verifyDefaultState.textInput, true)

		if (this.props.verifyDefaultState.textDS.length > 0) {
			try {
	            var arrToSend = this.props.actions.toByteArray(this.props.verifyDefaultState.textDS);
        		var dsData = new Uint8Array(arrToSend)
	        } catch (e) {
	            alert(i18next.t("ds:illegalBase64SignatureFormat"));
	            return;
	        }
		}

		if (this.props.defaultState.signatureType === 'detached') {

			var u8Arr = new Uint8Array(arr);
			this.props.actions.setInputTextForUpgradeUint8Arr(u8Arr)

	    	this.props.actions.setCadesType("CAdESXLong");

	    	this.setState({isLoading: true})

			this.props.actions.createSession(this.props.connectionStatus.baseUrl)
	        .then((response) => {
	          this.setState({uuid: response.ticketUuid})
	          console.log(response);
	          return this.props.actions.sendInputData(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.upgradeTextDSReducer.textForDSUint8Arr)
				.then(() => {
					return this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, dsData)
		            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
		            .then(() => this.props.actions.modifyDS(this.props.connectionStatus.baseUrl, this.state.uuid))
		            .then(() => this.props.actions.getModifiedDSBase64Data(this.props.connectionStatus.baseUrl, this.state.uuid))
		            .then((response) => {
		            	if (response !== null) {
		            		this.props.actions.setUpgradedDSInBase64(response.base64Data)
		            	}
	                })
		            .then(() => this.props.actions.getModifyDSResult(this.props.connectionStatus.baseUrl, this.state.uuid)
		            .then((response) => {
		            	if (response.modifyResults !== undefined) {
		        			if (response.modifyResults[0].currentSignatureFormat !== response.modifyResults[0].previousSignatureFormat) {
		        				this.props.actions.setSignatureDifferenceType(true)
		        			} else {
		        				this.props.actions.setSignatureDifferenceType(false);
		        			}
		        		}
		            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
		            		this.setState({responseFailureCause: response.failureCause})
		            	} else {
		            		// if (response.modifyResults !== undefined) {
		            		// 	var msg = i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle") + '\n' + i18next.t("ds:modifiedDSSucessTitle": "modifiedDSSucessTitle")
		            		// 	this.setState({responseMessage: msg})
		            		// }
		            		console.log(response)
		            	}
		            })
		            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
		            .then(() => this.verifyDS()))
				})
	        })
	    } else {
	    	this.modifyAttachedDS()
	    }
	}

	modifyAttachedDS() {
		var arr;

    	this.props.verifyDefaultState.textEncoding === 'UTF-16LE'
			? arr = this.props.actions.StringToUTF16LEArray(this.props.verifyDefaultState.textInput, false)
			: arr = this.props.actions.StringToArray(this.props.verifyDefaultState.textInput, true)

		if (this.props.verifyDefaultState.textDS.length > 0) {
			try {
	            var arrToSend = this.props.actions.toByteArray(this.props.verifyDefaultState.textDS);
        		var dsData = new Uint8Array(arrToSend)
	        } catch (e) {
	            alert(i18next.t("ds:illegalBase64SignatureFormat"));
	            return;
	        }
		}

		var u8Arr = new Uint8Array(arr);
		this.props.actions.setInputTextForUpgradeUint8Arr(u8Arr)

    	this.props.actions.setCadesType("CAdESXLong");

    	this.setState({isLoading: true})

		this.props.actions.createSession(this.props.connectionStatus.baseUrl)
        .then((response) => {
				this.setState({uuid: response.ticketUuid})
				console.log(response);

			return this.props.actions.sendDSData(this.props.connectionStatus.baseUrl, this.state.uuid, dsData)
            .then(() => this.props.actions.sendSessionOption(this.props.connectionStatus.baseUrl, this.state.uuid, this.props.defaultState))
            .then(() => this.props.actions.modifyDS(this.props.connectionStatus.baseUrl, this.state.uuid))
            .then(() => this.props.actions.getModifiedDSBase64Data(this.props.connectionStatus.baseUrl, this.state.uuid))
            .then((response) => {
            	if (response !== null) {
            		this.props.actions.setUpgradedDSInBase64(response.base64Data)
            	}
            })
            .then(() => this.props.actions.getModifyDSResult(this.props.connectionStatus.baseUrl, this.state.uuid)
            .then((response) => {
            	if (response.modifyResults !== undefined && response.modifyResults.length > 0) {
        			if (response.modifyResults[0].currentSignatureFormat !== response.modifyResults[0].previousSignatureFormat) {
        				this.props.actions.setSignatureDifferenceType(true)
        			} else {
        				this.props.actions.setSignatureDifferenceType(false);
        			}
        		}
            	if (response.failureCause !== undefined && response.failureCause.length > 0) {
            		this.setState({responseFailureCause: response.failureCause})
            	} else {
            		// if (response.modifyResults !== undefined) {
            		// 	var msg = i18next.t("ds:verifiedDSSuccessTitle": "verifiedDSSuccessTitle") + '\n' + i18next.t("ds:modifiedDSSucessTitle": "modifiedDSSucessTitle")
            		// 	this.setState({responseMessage: msg})
            		// }
            		console.log(response)
            	}
            })
            .then(() => this.props.actions.deleteSession(this.props.connectionStatus.baseUrl, this.state.uuid))
            .then(() => this.verifyDS()))
        })
	}

	render() {
		const isTextDSEnabled = this.props.defaultState.signatureType === "attached"
				? this.props.verifyDefaultState.textDS.length > 0
				: this.props.verifyDefaultState.textInput.length > 0 && this.props.verifyDefaultState.textDS.length > 0;

		const isEnabledCopyModifiedDS = this.props.upgradeTextDSReducer.upgradedDSInBase64.length > 0;
		return (
			<div style={{paddingTop: "10px"}}>
				<div className="card">
	                    <div className="card-header" onClick={this.expandVerifyDSText.bind(this)} role="button">
	                    {
							this.props.expandedCardsReducer.verifyTextExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
							: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
						}&nbsp;{i18next.t("textData")}
	                    </div>
	                  	<div className={this.state.collapseVerifyDSTextShow}>
	                    <div>
	                    	<div className="form">
	                    		<label>{i18next.t("encoding")}</label>
	                    		<div className="form-check-inline">
		                            <label className="form-check-label" style={{marginLeft: "7px"}}>
		                                <input className="form-check-input" value="UTF-16LE" type="radio" checked={this.props.verifyDefaultState.textEncoding === 'UTF-16LE'} onChange={this.textEncodingHendler}/>
		                                <span className="form-check-label">{i18next.t("utf16LeEncoding")}</span>
		                            </label>
		                            <label className="form-check-label" style={{marginLeft: "7px"}}>
		                                <input className="form-check-input" value="UTF-8" type="radio" checked={this.props.verifyDefaultState.textEncoding === 'UTF-8'} onChange={this.textEncodingHendler} />
		                                <span className="form-check-label">{i18next.t("utf8Encoding")}</span>
		                            </label>
			                    </div>
	                    	</div>
	                    	{
		                    	this.props.defaultState.signatureType === "attached" 
		                    	? null
		                    	: <div>
		                    		<label>{i18next.t("ds:textDataFoVerifyingTitle")}</label>
			                        <div className="input-group mb-4">
										<textarea id="dscTextForDs" rows="5" className="form-control" value={this.props.verifyDefaultState.textInput} onInput={this.textInputHendler}></textarea>
									</div>
		                    	</div>
		                    }

		                    <div>
								<label>{i18next.t("ds:dsvBase64Title")}</label>
								<textarea style={{"marginBottom": "15px"}} id="dscDsBase64" rows="3" className="form-control" value={this.props.verifyDefaultState.textDS} onInput={this.textDSInputHendler}> </textarea>
							</div>
	                    	
							{
								this.props.defaultState.signatureType === "attached" 
									? <div>
										<label>{i18next.t("ds:dataFromSignedTextTitle")}</label>
				                        <div className="input-group mb-4">
											<textarea id="dscTextForDs" rows="5" className="form-control" value={this.props.verifyDefaultState.dsDataInBase64} readOnly></textarea>
										</div>
									</div> : null
							}

							{
								this.props.verifyDefaultState.verifyResultsText ? this.showDsVerifyResultsText(this.props.verifyDefaultState.verifyResultsText, this.props.verifyDefaultState.divId)
									: null
							}

							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-0 order-md-4">
			                        <button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.startVerify.bind(this)} disabled={!isTextDSEnabled}>{i18next.t("ds:verifyDs")}
			                        	{
			                              	this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
			                                : null
			                            }
			                        </button>
		                        </div>
		                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 order-2 order-md-6 no-left-padding">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
		                        </div>
	                        </div>
	                        <div className="row">
	                        	{
		                        	this.props.defaultState.showUpgradeCard && this.props.verifyDefaultState.differenceSignatureType
		                        	? <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		                        		<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={!isEnabledCopyModifiedDS} onClick={this.copyModifiedData.bind(this)}>{i18next.t("ds:copyModifiedTextDSTitle")}</button>
		                        	</div>
		                        	: null
		                        }
		                    </div>
	                    </div>
		            </div>
		    	</div>
		    </div>
		);
	}
}

function mapStateToProps(state) {
    return {
        locales: state.i18n,
        localesReducer: state.localesReducer,
        verifyInputDS: state.setVerifyDSInput,
        defaultState: state.verifyDSOptionsReducer,
        verifyDefaultState: state.verifyDSTextInputReducer,
        connectionStatus: state.connectionStatusReucer,
        expandedCardsReducer: state.expandedCardsReducer,
        upgradeTextDSReducer: state.upgradeTextDSReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    createSession,
	    sendInputData,
	    sendDSData,
	    sendSessionOption,
	    verifyDSData,
	    getVerifiedData,
	    deleteSession,
	    setVerifyResultData,
	    setDSEncodingType,
	    setVerifyDSEncodingType,
	    StringToUTF16LEArray,
	    StringToArray,
	    setInputTextForVerifyDS,
	    toByteArray,
	    setVerifyResultText,
	    setVerifyInputText,
	    setInputTextDSForVerify,
	    getSignedData,
	    setTextSignedData,
	    ArrayToString,
	    UTF16LEArrayToString,
	    clearInputTextDSForVerify,
	    clearVerifyInputText,
	    clearVerifyResultText,
	    clearTextSignedData,
	    setVerifyDSTextExpand,
	    setInputTextForUpgradeUint8Arr,
	    setCadesType, modifyDS,
	    getModifiedDSBase64Data, getModifyDSResult,
	    setUpgradedDSInBase64,
	    setSignatureDifferenceType,
	    clearUpgradedDSInBase64
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyTextDSContainer);