import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import saveAs from 'file-saver';
import { languageSwitchItem } from '../actions/locales'
import i18next from 'i18next'
import { JSEncrypt } from 'jsencrypt'
import aes from 'crypto-js/aes'
import { cipher } from '../actions/stringEncodersFuncs'
import { createSession, sendInputData, sendSessionOption, sendKeyContainer, decryptData,
			getDecryptedData, deleteSession, sendTokenPath, getDecryptorStatus, decryptVtco, premission2fVtco, premissionVtco } from '../actions/api'
import { setInputFileForDecrypt, clearInputFileForDecrypt, setDecryptedData, clearDecryptedData, setDecryptExpand } from '../actions/localStates'
import { bindActionCreators } from 'redux';

import TwoFactorConfirmPopup from '../components/body/TwoFactorConfirmPopup'

import DecryptTextContainer from './DecryptTextContainer'

import Dropzone from 'react-dropzone';

const dropzoneRef = createRef();

class DecryptContainer extends Component {

	constructor(props) {
    super(props);

	    this.state = {
			isLoading: false,
			uuid: null,
			settedToken: null,
			caId: null,
			downloadIsAvailable: true,
			collapseDecryptShow: "",
			showVtcoConfirmPopup: false,
      		numberOfPinInput: 0,
			needToReEnterPin: false
		}

		this.withToken = this.withToken.bind(this)
		this.handleOnDrop = this.handleOnDrop.bind(this);
	}

	expandDecrypt() {
		this.props.actions.setDecryptExpand(this.props.expandedCardsReducer.decryptExpanded)
		this.props.expandedCardsReducer.decryptExpanded ? this.setState({collapseDecryptShow: "card-body collapse"}) : this.setState({collapseDecryptShow: "card-body collapse show"})
	}

	buildFileForDecryptSelector(){
		const fileForDecrypt = document.getElementById('fileForDecryptUpload');
		return fileForDecrypt;
	}

	componentDidMount(){
		var dropFile = document.getElementById('dropFile');

		this.props.expandedCardsReducer.decryptExpanded ? this.setState({collapseDecryptShow: "card-body collapse show"}) : this.setState({collapseDecryptShow: "card-body collapse"})
    	this.fileForDecrypt = this.buildFileForDecryptSelector();
    	if (!this.props.pkLocalState.isActive) {
			this.cleanUpForm()
		}

		if (dropFile !== null) {
			dropFile.addEventListener('dragenter', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragover', function(event) {
				dropFile.style.border = "2px solid steelblue";
			}, false);

			dropFile.addEventListener('dragleave', function(event) {
				dropFile.style.border = "";
			}, false);
		}
	}

	componentDidUpdate(prevProps) {
		var dropFile = document.getElementById('dropFile');

		if (prevProps.fileForDecryptSelected !== this.props.defaultDecrState.fileForDecryptSelected) {
			if (dropFile !== null) {
				dropFile.addEventListener('dragenter', function(event) {
					dropFile.style.border = "2px solid steelblue";
				}, false);

				dropFile.addEventListener('dragover', function(event) {
					dropFile.style.border = "2px solid steelblue";
				}, false);

				dropFile.addEventListener('dragleave', function(event) {
					dropFile.style.border = "";
				}, false);
			}
		}
	}

	handleFileForDecrypt(e) {
	    e.preventDefault();
	    this.fileForDecrypt.value = null;
	    this.fileForDecrypt.click()
	    document.activeElement.blur()
	}

	onChangeFileForDecrypt(e) {
		if (e.target.files[0] !== undefined && (e.target.files[0].size < 104857600)) {
			this.props.actions.setInputFileForDecrypt(e.target.files[0])
			this.props.actions.clearDecryptedData()
		} else {
			alert(i18next.t("maxFileSizeTitle"))
		}
	}

	handleOnDrop (files) {
		if (files[0] !== undefined && (files[0].size < 104857600)) {
			this.props.actions.setInputFileForDecrypt(files[0])
			this.props.actions.clearDecryptedData()
		}
	}

	cleanUpFileForDecrypt(){
		this.props.actions.clearInputFileForDecrypt()
		this.props.actions.clearDecryptedData()
	}

	cleanUpForm(){
		this.cleanUpFileForDecrypt()
		this.props.actions.clearDecryptedData()
	}

	withToken() {
		var url, _this = this;

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		var keyContainerPassword
	    if (this.props.pkLocalState.depositsignSelectedKey !== null) {
	        const publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCDAChZGnfpj1SsGLIPCaHaL7ybqpMgvphowUroGp7WZ3XA7O3ucaHfIxQFOMNAKUooIkebpp+tLO6imROzxVTmZfySpNNdfBx3E3BU9VzXCBBEKyCrmNxH3AWwuQeoi1Z1ZZLFDI02AjQYR8Nd7TutDGS7FuYlMmIKHYW2+NGHSQIDAQAB";

	        const jsEncrypt = new JSEncrypt();
	        jsEncrypt.setPublicKey(publicKey);

	        if (this.props.pkLocalState.useTwoFactorCode) {
	            keyContainerPassword = aes.encrypt(this.props.pkLocalState.tmpPin, this.props.pkLocalState.tmpTwoFactorCode).toString();
	        } else { 
	            keyContainerPassword = jsEncrypt.encrypt(this.props.pkLocalState.tmpPin);
	        }
	    } else {
	        keyContainerPassword = this.props.pkLocalState.keyContainerPassword
	    }

		this.setState({isLoading: true})
		this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
        	.then((response) => {
				this.setState({uuid: response.ticketUuid})
				console.log(response);
			return this.props.actions.sendInputData(url, this.state.uuid, this.props.defaultDecrState.fileForDecrypt, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
				.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, this.state.caId, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then(() => this.props.actions.sendTokenPath(url, this.state.uuid, this.state.settedToken, this.props.pkLocalState.depositsignKeysInfo !== null ? this.props.pkLocalState.depositsignKeysInfo.Token : null, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
            	.then(() => this.props.actions.decryptData(url, this.state.uuid, keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	            .then(() => this.props.actions.getDecryptedData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
	           	.then((response) => {
	           		if (response !== null) {
            			this.props.actions.setDecryptedData(response)
            		}
                })
                .then(() => this.props.actions.getDecryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
                .then((response) => {
                	if (response.failureCause !== undefined && response.failureCause.length > 0) {
                    	console.log(response)
                    	alert(response.failureCause)
            		} else {
            			// alert(i18next.t("dec:decryptedDataDownloadedSuccess": "decryptedDataDownloadedSuccess"))
            		}
            		this.setState({isLoading: false})
                })
	            .then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
        })
	}

	getBlobData() {
	 	var fileForDownloadName = this.props.defaultDecrState.fileForDecryptName.lastIndexOf(".") < 2 ? this.props.defaultDecrState.fileForDecryptName : this.props.defaultDecrState.fileForDecryptName.substr(0, this.props.defaultDecrState.fileForDecryptName.lastIndexOf("."));
	 	saveAs(this.props.defaultDecrState.decrData, fileForDownloadName)
	}

	selectDecryptVtco () {
		if (this.props.pkLocalState.isTwoFactorEnable) {
			// this.createVtcoSign2f()
			this.setState({numberOfPinInput: this.props.base.numberOfPinInput - 1});
			this.setState({needToReEnterPin: true});
			this.setState({"showVtcoConfirmPopup": true})
		} else {
			this.decryptVtco()
		}
	}

	closeVtcoConfirm (value) {
		if (value === true) {
			this.setState({"showVtcoConfirmPopup": false})
			return;
		} else {
			if (this.state.showVtcoConfirmPopup === true && this.state.needToReEnterPin === true) {
				if (this.state.numberOfPinInput > 0) {
					this.decryptVtco2f()
				} else {
					this.setState({needToReEnterPin: false});
					this.setState({numberOfPinInput: 0});
					this.setState({isLoading: false});
				}
			}

			this.setState({"showVtcoConfirmPopup": false})
		}
	}

	decryptVtco2f () {
		var data, _this = this;
		this.setState({isLoading: true})

		data = {
		    "ciHsmTokenUri": this.props.pkLocalState.vtcoSelectedKey.uri,
		    "operationType": "DECIPHERMENT",
		    "permissionTtlSeconds" : 10,
		    "permissionRepetition" : 2
		}

		this.props.actions.premission2fVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    .then((response) => {
	    	if (response.code !== undefined) {
	    		_this.setState({isLoading: false})
	    		var msg = response.message + "\n" + response.techMessage
	    		alert(msg);
	    	} else {
	    		data = {
				    "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
				    "operationType": "DECIPHERMENT",
				    "twoFactorCode" : _this.props.pkLocalState.vtcoTwoFactorCode
				}

	    		_this.props.actions.premissionVtco(this.props.base.vtcoURL, data, this.props.pkLocalState.vtcoTokenBody)
	    		.then((response) => {
			    	if (response.code !== undefined) {
			    		if (response.code === "AUTHCOMMON-14") {
	                		var currentCount = _this.state.numberOfPinInput

	                		if (currentCount === 0) {
	                			currentCount = _this.props.base.numberOfPinInput - 1;
	                		} else {
	                			currentCount = currentCount - 1;
	                		}

	                		_this.setState({numberOfPinInput: currentCount});
							_this.setState({needToReEnterPin: true});
							_this.setState({showVtcoConfirmPopup: true})
	                	} else {
	                		_this.setState({isLoading: false})
				    		var msg = response.message + "\n" + response.techMessage
				    		alert(msg);
	                	}
			    	} else {
			    		_this.decryptVtco();
			    	}
			    })
	    	}
	    })
	}

	decryptVtco () {
		var _this = this, sendData, input;
		this.setState({isLoading: true})

		sendData = function () {
			var jsonObject = {
	        	pin : _this.props.pkLocalState.vtcoPin,
				dataToDecipher : [
					input
				]
		    };

		    var cipherResult = cipher(jsonObject, _this.props.pkLocalState.publicKeyTextual, _this.props.pkLocalState.currentUtcDateTime);

		    var data = {
		        "ciHsmTokenUri": _this.props.pkLocalState.vtcoSelectedKey.uri,
		        "encryptedData": cipherResult.encryptedData,
		        "secretKey": cipherResult.secretKey
		    }

		    _this.props.actions.decryptVtco(_this.props.base.vtcoURL, data, _this.props.pkLocalState.vtcoTokenBody)
		    .then((response) => {
		    	if (response.code !== undefined) {
		    		_this.setState({isLoading: false})
		    		var msg = response.message + "\n" + response.techMessage
		    		alert(msg);
		    	} else {
			    	_this.props.actions.setDecryptedData(response)
			    	_this.setState({isLoading: false})
		    	}
		    })
		}

		var reader = new FileReader();
        reader.readAsBinaryString(_this.props.defaultDecrState.fileForDecrypt);
        reader.onload = function (evt) {
            var binaryData = evt.target.result;
            var base64String = window.btoa(binaryData);

            input = base64String;
            sendData();

        }
        reader.onerror = function (evt) {
            document.getElementById("fileContents").innerHTML = "error reading file";
        }
	}

	decrypt() {
		var url, _this = this;
		let settedToken

		if (this.props.base.isVtco) {
			this.selectDecryptVtco();
			return;
		}

		if (this.props.pkLocalState.useUccConnection) {
			url = this.props.base.urlUCC + "/api/v1"
		} else {
			url = this.props.base.urlCloud + "/api/v1"
		}

		if (this.props.pkLocalState.tokenPathHSM.length > 0) {
			settedToken = {
				keyStoreUri: this.props.pkLocalState.tokenPathHSM
			}
		} else {
			if (this.props.pkLocalState.depositsignSelectedKey !== null) {
				settedToken = {
					keyStoreUri: this.props.pkLocalState.depositsignSelectedKey.KeyName
				}
			} else {
				settedToken = {
					keyStorePath: this.props.pkLocalState.activeToken ? this.props.pkLocalState.activeToken : this.props.pkLocalState.passiveToken
				}
			}
		}

		let caId = {
	        caId: this.props.pkLocalState.selectedKeyContainer
	    }

		if (this.props.pkLocalState.keyContainer !== null) {
			if (this.props.connectionStatus.uccConnection && typeof this.props.pkLocalState.keyContainer === 'string') {
				let keyPath = {"keyStorePath": this.props.pkLocalState.keyContainer}
	            this.setState({"settedToken": keyPath})
	            this.setState({"caId": caId})
	            this.withToken()
			} else {
				this.setState({isLoading: true})
				this.props.actions.createSession(url, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
		        	.then((response) => {
						this.setState({uuid: response.ticketUuid})
						console.log(response);
					return this.props.actions.sendInputData(url, this.state.uuid, this.props.defaultDecrState.fileForDecrypt, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token)
						.then(() => this.props.actions.sendSessionOption(url, this.state.uuid, caId, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then(() => this.props.actions.sendKeyContainer(url, this.state.uuid, this.props.pkLocalState.keyContainer, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
		            	.then(() => this.props.actions.decryptData(url, this.state.uuid, this.props.pkLocalState.keyContainerPassword, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			            .then(() => this.props.actions.getDecryptedData(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
			           	.then((response) => {
			           		if (response !== null) {
		            			this.props.actions.setDecryptedData(response)
		            		}
		                })
		                .then(() => this.props.actions.getDecryptorStatus(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
		                .then((response) => {
		                	if (response.failureCause !== undefined && response.failureCause.length > 0) {
		                    	console.log(response)
		                    	alert(response.failureCause)
		            		} else {
		            			// alert(i18next.t("dec:decryptedDataDownloadedSuccess": "decryptedDataDownloadedSuccess"))
		            		}
		            		this.setState({isLoading: false})
		                })
			            .then(() => this.props.actions.deleteSession(url, this.state.uuid, _this.props.pkLocalState.useUccConnection ? null : _this.props.base.token))
		        })
		    }
		} else {
			this.setState({"settedToken": settedToken})
            this.setState({"caId": caId})
            this.withToken()
		}
    }

	render() {
		const isEnabled = this.props.defaultDecrState.fileForDecryptSelected && !this.state.isLoading
		const downloadEnable = this.props.defaultDecrState.decrData && this.state.downloadIsAvailable
		return (
            <div className="row" style={{paddingTop: "10px"}}>
				<div className="col-12">
	                <div className="card">
						<div className="card-header" onClick={this.expandDecrypt.bind(this)} role="button">
							{
								this.props.expandedCardsReducer.decryptExpanded ? <i className="fa fa-chevron-down" aria-hidden="true" style={{"cursor": "pointer"}}></i>
								: <i className="fa fa-chevron-up" aria-hidden="true" style={{"cursor": "pointer"}}></i>
							}&nbsp;{i18next.t("file")}
						</div>
	                  	<div className={this.state.collapseDecryptShow}>
	                  		<div className="row">
		                  		<label className="col-lg-4 col-md-4 col-xs-12 col-sm-12 col-form-label">{i18next.t("dec:encryptedFileTitle")}</label>
	                            <div className="col-lg-8 col-md-8 col-xs-12 col-sm-12" style={{marginBottom: "10px"}}>
	                                {
	                                  this.props.mobileModeReducer.mobileDeviceMode
	                                  ? 
	                                  <div className="input-group row" style={{marginLeft: "0px"}}>
	                                    <input type="text" className="form-control col-lg-8 col-md-8 col-xs-12 col-sm-12" value={this.props.defaultDecrState.fileForDecryptName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleFileForDecrypt.bind(this)} readOnly />
	                                    {
											this.props.defaultDecrState.fileForDecryptSelected
											? <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handleFileForDecrypt.bind(this)}>{i18next.t("changeFile")}</button>
											: <button className="btn btn-default col-lg-4 col-md-4 col-xs-12 col-sm-12 margin-top-sm" style={{"cursor": "pointer"}} onClick={this.handleFileForDecrypt.bind(this)}>{i18next.t("chooseFile")}</button>
										}
	                                    </div>
	                                    : <div>
	                                    	{
	                                    		this.props.defaultDecrState.fileForDecryptSelected
	                                    		? <div className="input-group mb-3">
													<input type="text" className="form-control col-lg-8 col-md-8 col-xs-12 col-sm-12 max-width" value={this.props.defaultDecrState.fileForDecryptName} style={{"backgroundColor": "white", "cursor": "pointer"}} onClick={this.handleFileForDecrypt.bind(this)} readOnly />
		                                    		<div className="input-group-append">
												      
													    <button className="input-group-text" style={{"cursor": "pointer"}} onClick={this.cleanUpFileForDecrypt.bind(this)}>{i18next.t("cleanup")}</button>
												    </div>
												</div>
											    : <Dropzone ref={dropzoneRef} onDrop={this.handleOnDrop} multiple={false}>
							                        {({getRootProps, getInputProps}) => (
							                            <div {...getRootProps()} style={{cursor: "pointer", marginBottom: "8px"}} className="dropzone" id="dropFile">
							                                <input {...getInputProps()} />
							                                <p style={{cursor: "pointer", marginTop: "16px"}}>{i18next.t("dragNDropTitleSecond")}</p>
							                            </div>
							                        )}
							                    </Dropzone>
	                                    	}
										</div>
	                                }
	                                <input id="fileForDecryptUpload" type="file" onChange={this.onChangeFileForDecrypt.bind(this)} style={{"display": "none"}} />
	                            </div>
	                        </div>
							<div className="row">
								<div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 order-0 order-md-4">
									<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px"}} onClick={this.decrypt.bind(this)} disabled={!isEnabled}>{i18next.t("dec:performBinaryDataDecryption")}
										{
			                                this.state.isLoading ? <span>&nbsp;<i className="fa fa-spinner fa-pulse"></i></span>
			                                : null
			                            }
									</button>
								</div>
								<div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 order-1 order-lg-5 no-left-padding">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{marginBottom: "10px", whiteSpace: "normal"}} disabled={!downloadEnable} onClick={this.getBlobData.bind(this)}>{i18next.t("dec:saveBinaryDecryptedData")}</button>
		                        </div>
		                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 order-2 order-md-6 no-left-padding">
		                        	<button className="btn btn-default col-lg-12 col-md-12 col-sm-12 col-xs-12" disabled={this.state.isLoading} onClick={this.cleanUpForm.bind(this)}>{i18next.t("cleanUpForm")}</button>
		                        </div>
	                        </div>
	                    </div>
	                </div>
	                <DecryptTextContainer />
	                {
						this.state.showVtcoConfirmPopup
						? <TwoFactorConfirmPopup initAction={this.closeVtcoConfirm.bind(this)} title={"decryptConfirm"} />
						: null
					}
                </div>
            </div>
		);
	}
}

function mapStateToProps(state) {
    return {
    	base: state.base,
        locales: state.i18n,
        localesReducer: state.localesReducer,
        pkLocalState: state.privateKeyReducer,
        defaultDecrState: state.decryptReducer,
        connectionStatus: state.connectionStatusReucer,
        mobileModeReducer: state.mobileModeReducer,
        expandedCardsReducer: state.expandedCardsReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    createSession,
	    sendInputData,
	    sendSessionOption,
	    sendKeyContainer,
	    decryptData,
	    getDecryptedData,
	    deleteSession,
	    setInputFileForDecrypt,
	    clearInputFileForDecrypt,
	    setDecryptedData,
	    clearDecryptedData,
	    sendTokenPath,
	    getDecryptorStatus,
	    setDecryptExpand, decryptVtco,
	    cipher, premission2fVtco, premissionVtco
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DecryptContainer);