import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import { bindActionCreators } from 'redux'
import { languageSwitchItem, getCurrentLanguage } from '../../actions/locales'
import { getSupportedCA, getConnecteds, changeServiceLanguage } from '../../actions/api'
import { setListCSK, setPassiveTokenPath, setActiveTokenPath, showVisualImpairmentView, setContrast, setFontSize } from '../../actions/localStates'
import ServiceConnectionStatus from './ServiceConnectionStatus'
import Downloads from './Downloads'
import CountDownTimer from './CountDownTimer'

import logo from '../../img/cripto_256.svg'
import logoUK from '../../img/splash-dzo.png'
import logoENG from '../../img/splash-dzo.png'
import logoRU from '../../img/splash-dzo.png'

const availableLanguege = ["uk", "en"];
const availableLanguegeValue = ["УКР", "ENG"];

class Header extends Component {

	constructor(props) {
    	super(props);
    	this.state = {
	    	currentLanguege: "logoUK"
	    }

		this.showVisualImpairmentView = this.showVisualImpairmentView.bind(this)
		this.setContrast = this.setContrast.bind(this)

		this.setFontSizePlus = this.setFontSizePlus.bind(this)
		this.setFontSizeMinus = this.setFontSizeMinus.bind(this)
		this.setFontSizeReset = this.setFontSizeReset.bind(this)

	}

	showVisualImpairmentView () {
		this.props.actions.showVisualImpairmentView(!this.props.baseUrls.showVisualImpairmentView)
	}

	setContrast () {
		this.props.actions.setContrast(!this.props.baseUrls.contrast)
	}

	setFontSizePlus () {
		if (this.props.baseUrls.fontSize < 4) {
			this.props.actions.setFontSize(this.props.baseUrls.fontSize + 1)
		} else {
			this.props.actions.setFontSize(4)
		}
	}

	setFontSizeMinus () {
		if (this.props.baseUrls.fontSize > 0) {
			this.props.actions.setFontSize(this.props.baseUrls.fontSize - 1)
		} else {
			this.props.actions.setFontSize(0)
		}
	}

	setFontSizeReset () {
		this.props.actions.setFontSize(0)
	}

	componentDidMount() {
		document.title = i18next.t("serviceTitle");
		i18next.changeLanguage('uk');
		this.props.actions.getCurrentLanguage('uk');
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.connectionStatus.baseUrl !== null) {
			if (nextProps.connectionStatus.baseUrl !== this.props.connectionStatus.baseUrl) {
				this.props.actions.getSupportedCA(nextProps.connectionStatus.baseUrl)
				.then((response) => {
					this.props.actions.setListCSK(response.ca)
				})

				if (nextProps.connectionStatus.uccConnection) {
					this.props.actions.getConnecteds(nextProps.connectionStatus.baseUrl)
			    	.then((response) => {
			    		console.log(response);
			    		if (response.passiveTokenPath.length > 0) {
			    			this.props.actions.setPassiveTokenPath(response.passiveTokenPath)
			    		}
			    		if (response.activeTokenPath.length > 0) {
			    			this.props.actions.setActiveTokenPath(response.activeTokenPath)
			    		}
			    	})
			    }
			}
		} else {
			return
		}
	}

	changeLang(lang){
		this.props.actions.getCurrentLanguage(lang);
        this.props.actions.languageSwitchItem('eng', lang, availableLanguege);
        this.props.actions.changeServiceLanguage(this.props.connectionStatus.baseUrl, lang);
        document.title = i18next.t("serviceTitle");
    }

    _renderHr () {
	      return (
	      	<div>
		      	{
		      		this.props.language === "en"
		      		? <hr style={{marginTop: "10px", marginBottom: "10px"}} />
		      		: <hr style={{marginTop: "10px", marginBottom: "10px"}} /> 
		      	}  
		    </div>    
	      );
	}

    _renderLogo() {
    	let active
	    function options(child, index) {
		    switch (child) {
			    case "uk":
			    	active = logoUK
			    break;
			    
			    case "ru":
			    	active = logoRU
			    break;

			    case "en":
			    	active = logoENG
			    break;

			    default:
			    	active = logoUK
			    break;
			}

	      return (
	        <img className="img" src={active} width="256" height="64" alt={active}/>
	      );
	    }

	    return (
	    	<div className="col-md-1 col-sm-1 col-xs-1">
	    		{options(this.props.language)}
	        </div>
	    );
	}

    _renderLanguageButtonLine() {

	    function options(child, index) {
	      let activeClass = (this.props.language === child ? 'btn btn-sm btn-header-active' : 'btn btn-header btn-sm');
	      let textValue = (availableLanguegeValue[index])

	      return (
	        <button key={child} className={activeClass} style={{marginRight: "7px"}} onClick={this.changeLang.bind(this, child)}>{textValue}</button>
	      );
	    }

	    return (
	    	<div id="languageButtonLine" className="col-md-11 col-sm-11 col-xs-11 text-right" style={{marginTop: "14px"}}>
	    		{
	    			this.props.pkLocalState.isActive
	    			? <button disabled className="btn btn-default btn-sm header-font-weight" style={{cursor: "none", backgroundColor: "transparent", color: "#283a80"}}><CountDownTimer /></button>
	    			: null
	    		}
	    		{availableLanguege.map(options.bind(this))}
	        </div>
	    );
	}

	render() {
		return (
			<div className="col-md-12 col-sm-12 col-xs-12">
				<div>
		        	{
		        		this.props.baseUrls.showVisualImpairmentView
		        		? <div className="margin-padding-0" style={{paddingTop: "7px", paddingBottom: "7px"}}>
		        			<div className="visual-impairment">
			        			<div className="visual-impairment-container">
				        			<div className="visual-impairment-div">
				        				<div className="visual-impairment-buttons">
				        					<div className="visual-impairment-filter">
				        						<div className="visual-impairment-title">
				        							{i18next.t("contrast")}
				        						</div>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setContrast}><i class="fa fa fa-adjust"></i></button>
				        					</div>
				        					<div className="visual-impairment-panel-size">
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizePlus}><i className="fa fa-plus"></i></button>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizeMinus}><i className="fa fa-minus"></i></button>
				        						<button className="btn visual-impairment-panel-buttons" onClick={this.setFontSizeReset}><i className="fa fa-rotate-right revert-img"></i></button>
				        					</div>
					        			</div>
					        			<div className="visual-impairment-hide">
					        				<button className="btn visual-impairment-panel-buttons" onClick={this.showVisualImpairmentView}><i className="fa fa-times"></i></button>
					        			</div>
				        			</div>
				        		</div>
			        		</div>
			        	</div>
		        		: null
		        	}
		        </div>

				<div>
					<div className="dzo-bg" style={{borderRadius: "0.25rem"}}>
						<div className="row">
					        {this._renderLogo()}
					        {this._renderLanguageButtonLine()}
					    </div>
						
					</div>
				</div>	
				<div className="row mtb-default" style={{marginBottom: "0px"}}>
			        <div className="col-md-1 col-sm-1 col-xs-1 text-left">
			            <img className="img" src={logo} width="64" height="64" alt="Logo" />
			        </div>
			        <div className="col-md-7 col-sm-10 col-xs-10 text-left" style={{color: "#139DD9"}}>
			            <h4 style={{marginLeft: "0px", font: "HelveticaBold"}} className="d-none d-sm-none d-md-block header-font-weight" id="clientForService">{i18next.t("clientForService")}</h4>
			            <h4 style={{marginLeft: "20px", font: "HelveticaBold"}} className="d-none d-sm-block d-md-none header-font-weight" id="clientForService">{i18next.t("clientForService")}</h4>
			            <h4 style={{font: "HelveticaBold", marginLeft: "30px"}} className="d-block d-sm-none margin-left-20-font-size header-font-weight" id="clientForService">{i18next.t("clientForService")}</h4>
			        </div>
			        <div className="col-md-4 col-sm-12 col-xs-12 text-center text-muted">
			        	<ServiceConnectionStatus connectionStatus={this.props.connectionStatus} url={this.props.baseUrls} />
			        </div>
			    </div>
			    <div style={{textAlign: "right", paddingTop: "7px"}}>
		        	<span style={{cursor: "pointer"}} onClick={this.showVisualImpairmentView}><i class="fa fa-low-vision"></i>&nbsp;{i18next.t("visualImpairment")}</span>
		        </div>
			    {this._renderHr()}
			    <Downloads connectionStatus={this.props.connectionStatus.status} url={this.props.baseUrls} />		    
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        localesReducer: state.localesReducer,
        language: state.localesReducer.language,
        connectionStatus: state.connectionStatusReucer,
        pkLocalState: state.privateKeyReducer,
        baseUrls: state.base
    }
}

const mapDispatchToProps = (dispatch) => {
    const actions = {
	    languageSwitchItem,
	    getCurrentLanguage,
	    getSupportedCA,
	    setListCSK,
	    getConnecteds,
	    setPassiveTokenPath,
	    setActiveTokenPath,
	    changeServiceLanguage, showVisualImpairmentView, setContrast, setFontSize
    };
    return {
       actions: bindActionCreators(actions, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);